<template>
    <modal ref="modalAgregarCarpeta" titulo="Crear Carpeta" adicional="Guardar" @adicional="crearCarpeta">
        <div class="row justify-content-center m-3 f-15">
            <div class="col-12 my-2">
                <ValidationObserver ref="addFolderGallery">
                    <p class="input-label-top">Nombre de la carpeta</p>
                    <ValidationProvider v-slot="{errors}" name="nombre" rules="required">
                        <el-input v-model="nombreCarpeta" class="w-100" size="small" />
                        <vee-error :text="errors[0]" />
                    </ValidationProvider>
                </ValidationObserver>
            </div>
        </div>
    </modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    data(){
        return {
            nombreCarpeta: '',
            section: '',
            idSection: '',
            editing: false,
            idItem: null,
        }
    },
    methods: {
        ...mapActions({
            Action_create_folder: 'obras/gallery/Action_create_folder',
            Action_edit_folder: 'obras/gallery/Action_edit_folder',
            //Action_get_folders: 'obras/gallery/Action_get_folders',
        }),
        toggle(section, id, data){
            this.$refs.addFolderGallery.reset()
            this.section = section
            this.idSection = id
            if (data) {
                console.log('data',data);
                this.editing = true
                this.nombreCarpeta = data.nombre
                this.idItem = data.id
            }
            this.$refs.modalAgregarCarpeta.toggle()
        },
        async crearCarpeta(){
            let validCreate = await this.$refs.addFolderGallery.validate()
            let obj = {
                "nombre" : this.nombreCarpeta,
                "id_tipo": null, // (7) galeria - (8) documentacion
                "id_obra": this.idSection
            }

            switch (this.section) {
                case 'galeria':
                    obj.id_tipo = 7
                    break;
                case 'documentacion':
                    obj.id_tipo = 8
                    break;
                default: ''
                    break;
            }

            if (validCreate) {
                if (this.editing) {
                    obj.id = this.idItem
                    await this.Action_edit_folder(obj)
                } else {
                    this.Action_create_folder(obj)
                    this.nombreCarpeta = ''
                }
                this.$refs.modalAgregarCarpeta.toggle()
            }
            
        }
    }
}
</script>